/* custom.css */
.text-container {
  margin: 0 auto;
  max-width: 90%;
}

.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensure the image fits within the slide without being cut off */
}

.carousel-image {
  width: 100%;
  height: auto;
  max-height: 500px; /* Adjust based on your preference */
  object-fit: contain; /* Ensure the whole image is visible */
}


.bg-wedding-bg-light {
  background-color: #FFF5EE; /* Light background color */
}

.bg-wedding-bg {
  background-color: #F2E6DF; /* Primary background color */
}

.bg-wedding-accent {
  background-color: #324851; /* Accent color */
}

.text-wedding-text {
  color: #2F4F4F; /* Text color */
}

.button-wedding {
  background-color: #324851; /* Button background color */
  color: white; /* Button text color */
}
.button-wedding:hover {
  background-color: #3D2C2E; /* Button hover color */
}

.swiper-pagination-bullet {
    background: #ffcc00; /* Change color to match your theme */
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.swiper-pagination-bullet-active {
    background: #ff9900; /* Active color to match your theme */
}

.swiper-pagination-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none; /* Remove default background */
    width: 40px;
    height: 40px;
    margin: 0 5px; /* Adjust spacing as needed */
}

.swiper-pagination-bullet-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.swiper-pagination-bullet-active .swiper-pagination-bullet-icon {
    border: 2px solid #ff9900; /* Add border for active state */
}

.text-wedding-contrast {
    padding: 20px; /* Adjust the value as needed */
}

.user-select-text {
    user-select: text;
}

.card-padding {
    padding: 20px; /* Adjust the inner padding of the card as needed */
}

/* Ensure cards are the same size and centered */
.swiper-slide > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
}

/* Customize pagination dots */
.swiper-pagination .swiper-pagination-bullet {
    background-color: #000; /* Black color for the dots */
}

.black-dot {
    background-color: #000; /* Black color for the dots */
}

.large-dot {
    width: 12px; /* Adjust size as needed */
    height: 12px; /* Adjust size as needed */
}

@media (min-width: 768px) {
    .swiper-pagination .swiper-pagination-bullet-large {
        width: 16px; /* Adjust size for larger screens */
        height: 16px; /* Adjust size for larger screens */
    }
}




